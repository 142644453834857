import React from "react";
import PropTypes from "prop-types";

import OrderContext from "./../../OrderContext";
import { OrderObject } from "./../../Queries/OrderQuery";
import {lineStatuses} from "../../BusRules/orderLines";

class RemoveItems extends React.Component {
  state = { isOpen: false };

  render() {
    return (
      <OrderContext.Consumer>
        {({ isLineEditing }) => (
          <button
            className="button red"
            onClick={this.cancelSelected}
            disabled={this.disableButton(isLineEditing)}
          >
            Remove items
          </button>
        )}
      </OrderContext.Consumer>
    );
  }

  disableButton = isLineEditing => {
    const { selections, uiActive, order } = this.props;

    const remainingLines = order.orderLines.filter(
      line =>
        !lineStatuses.DELETED_LIST.includes(line.status) &&
        !selections.includes(line.id)
    );
    if (remainingLines.length === 0) {
      return false;
    }
    const onlyFreight = remainingLines.every(
      line => line.saleType === "FREIGHT" && line.status !== "Delivered"
    );

    return (
      onlyFreight || !uiActive || !(selections.length > 0 && !isLineEditing)
    );
  };

  cancelSelected = () => {
    const { saveLine, selections, clearSelections } = this.props;

    if (this.ifExternalConfirm()) {
      selections.forEach(id =>
        saveLine({
          id,
          status: "cancelled"
        })
      );
      clearSelections();
    }
  };

  ifExternalConfirm() {
    const { order } = this.props;

    if (order.partner && !order.partner.isInternal) {
      return window.confirm(
        "Are you sure you want to remove the line/s? " +
          "If there are no other active lines on the order, the order will " +
          "be closed and you will not be able to change the order or add any " +
          "other lines. Are you still sure?"
      );
    } else {
      return true;
    }
  }
}

RemoveItems.propTypes = {
  order: OrderObject.isRequired,
  saveLine: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  clearSelections: PropTypes.func.isRequired,
  uiActive: PropTypes.bool
};

export default RemoveItems;
