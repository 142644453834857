import React from "react";
import PropTypes from "prop-types";
import {AgGridReact} from "ag-grid-react";
import newServerSideDatasource from "./ServerSideDatasource";
import PresetFilterButton from "./PresetFilterButton";
import UserFilterDropdown from "./UserFilterDropdown";
import NewUserFilterModal from "./NewUserFilterModal";

class CmpAgGrid extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        suppressHeaderMenuButton: true,
        floatingFilter: true,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains", "startsWith"]
        },
        filter: true
      },
      columnTypes: {
        cmpLinkColumn: {
          pinned: "left",
          filter: "agTextColumnFilter"
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true
            }
          }
        ],
        position: "left"
      },
      currentFilterModel: {},
      presetFilterCounts: {},
      // Infinite row model does not use overlays so we can repurpose the loading overlay for errors
      overlayLoadingTemplate:
        "<div class='message message--error' role='alert'>There was an error when loading rows</div>"
    };
  }

  render() {
    return (
      <div>
        <div className="ag-grid-filter-buttons">
          {this.props.filterButtons && (
            <div className="preset-filters">
              <span>Quick Filters:</span>
              {this.props.filterButtons.map(button => (
                <PresetFilterButton
                  key={button.buttonText}
                  buttonText={button.buttonText}
                  onClick={e => this.setPresetFilter(e, button.filterModel)}
                  buttonFilterModel={button.filterModel}
                  currentFilterModel={this.state.currentFilterModel}
                  count={this.state.presetFilterCounts[button.countQueryField]}
                  hideOnZeroCount={button.hideOnZeroCount}
                />
              ))}
            </div>
          )}
          {this.props.secondaryFilterButtons && (
            <div className="preset-filters">
              {this.props.secondaryFilterButtons.map(button => (
                <PresetFilterButton
                  key={button.buttonText}
                  buttonText={button.buttonText}
                  onClick={e => this.setPresetFilter(e, button.filterModel)}
                  buttonFilterModel={button.filterModel}
                  currentFilterModel={this.state.currentFilterModel}
                  count={this.state.presetFilterCounts[button.countQueryField]}
                  hideOnZeroCount={button.hideOnZeroCount}
                />
              ))}
            </div>
          )}
          {this.props.userFilters && (
            <React.Fragment>
              <div className="preset-filters">
                <UserFilterDropdown
                  options={this.props.userFilters}
                  onChange={this.setUserFilter}
                />
              </div>
              <NewUserFilterModal
                grid={this.props.responseDataKey}
                filterModel={this.state.currentFilterModel}
                selectFilter={this.selectUserFilter}
                existingFilters={this.props.userFilters}
              />
            </React.Fragment>
          )}
          <button
            onClick={this.clearFilters}
            className="button button--small grey"
          >
            Clear filters
          </button>
          <button
            onClick={this.resetColumns}
            className="button button--small grey"
          >
            Reset columns
          </button>
        </div>
        <div
          className={`ag-theme-balham ag-grid-wrapper ${
            this.props.wrapperClass
          }`}
        >
          <AgGridReact
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            cacheBlockSize={100}
            headerHeight={40}
            groupHeaderHeight={20}
            rowHeight={this.props.rowHeight}
            floatingFiltersHeight={50}
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.props.defaultColumnDefs}
            columnTypes={this.state.columnTypes}
            allowDragFromColumnsToolPanel={true}
            suppressCsvExport={true}
            suppressExcelExport={true}
            enableRangeSelection={true}
            enableRangeHandle={true}
            initialState={this.initialState()}
            onGridReady={this.onGridReady}
            onStateUpdated={this.onStateUpdated}
            onFilterChanged={this.onFilterChanged}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            components={this.props.frameworkComponents}
            getContextMenuItems={this.getContextMenuItems}
            sideBar={this.state.sideBar}
            sortingOrder={["desc", "asc", null]}
          />
        </div>
      </div>
    );
  }

  getContextMenuItems = params => {
    let items = ["copy", "copyWithHeaders"];
    if (
      params.column &&
      params.column.colDef.type === "cmpLinkColumn" &&
      params.node.data
    ) {
      const colRef = params.column.colDef.colId || params.column.colDef.field;
      const cellRenderer = params.api.getCellRendererInstances({
        columns: [colRef],
        rowNodes: [params.node]
      });
      if (
        cellRenderer.length === 1 &&
        cellRenderer[0].props &&
        cellRenderer[0].props.link
      ) {
        items.unshift({
          name: "Open Link in New Tab",
          action: () => window.open(cellRenderer[0].props.link)
        });
      }
    }
    return items;
  };

  setUserFilter = event => {
    if (event.target.value === "") {
      this.clearFilters();
      return;
    }
    event.target.classList.add("active");
    try {
      const filterModel = JSON.parse(event.target.value);
      this.agGridApi.setFilterModel(filterModel);
    } catch (e) {
      console.log("Error applying filter:", e);
    }
  };

  selectUserFilter = selectedValue => {
    const userFilters = document.getElementById("user-filters");
    if (userFilters) {
      userFilters.value = selectedValue;
      userFilters.classList.add("active");
    }
  };

  clearUserFilter = () => {
    const userFilters = document.getElementById("user-filters");
    if (userFilters) {
      userFilters.value = "";
      userFilters.classList.remove("active");
    }
  };

  setPresetFilter = (event, filterModel) => {
    this.clearUserFilter();
    this.agGridApi.setFilterModel(filterModel);
  };

  clearFilters = () => {
    this.clearUserFilter();
    this.agGridApi.setFilterModel(null);
  };

  resetColumns = () => {
    if (
      window.confirm(
        "This will return all columns in this grid to their original position, are you sure?"
      )
    ) {
      window.localStorage.removeItem(this.gridStateStorageKey());
      this.agGridApi.resetColumnState();
    }
  };

  onGridReady = params => {
    this.agGridApi = params.api;
    const defaultFilter =
      this.props.userFilters &&
      this.props.userFilters.find(filter => filter.default);
    if (defaultFilter) {
      this.selectUserFilter(defaultFilter.filterModel);
    }

    const newDataCallback = data => {
      if (!this.props.filterButtons) return;
      let newCounts = { ...this.state.presetFilterCounts };
      (this.props.filterButtons || []).map(btn => {
        if (btn.countQueryField !== null) {
          newCounts[btn.countQueryField] = data[btn.countQueryField];
        }
      });
      this.setState({ presetFilterCounts: newCounts });
    };

    const datasource = newServerSideDatasource(
      this.agGridApi,
      this.props.gqlQuery,
      this.props.responseDataKey,
      this.props.pollingPathNameMatch,
      newDataCallback
    );
    this.agGridApi.setGridOption("serverSideDatasource", datasource);
  };

  initialState = () => {
    let savedState =
      JSON.parse(window.localStorage.getItem(this.gridStateStorageKey())) || {};
    if (savedState.rangeSelection && !this.props.preserveSelection) {
      delete savedState.rangeSelection;
    }
    const defaultFilter =
      this.props.userFilters &&
      this.props.userFilters.find(filter => filter.default);
    if (defaultFilter) {
      const defaultFilterModel = defaultFilter.filterModel;
      savedState.filter = { filterModel: JSON.parse(defaultFilterModel) };
    }
    return savedState;
  };

  onFilterChanged = params => {
    const currentFilterModel = params.api.getFilterModel();
    this.setState({ currentFilterModel: currentFilterModel });
  };

  onStateUpdated = params => {
    let state = params.state;
    delete state.filter;
    if (!this.props.preserveSelection) {
      delete state.rangeSelection;
    }
    this.saveGridState(state);
  };

  saveGridState = state => {
    window.localStorage.setItem(
      this.gridStateStorageKey(),
      JSON.stringify(state)
    );
  };

  gridStateStorageKey = () => {
    const keyPrefix = this.props.localStorageKey || this.props.responseDataKey;
    return keyPrefix + "-GridState-" + this.props.userId;
  };
}

CmpAgGrid.defaultProps = {
  preserveSelection: true
};

CmpAgGrid.propTypes = {
  userId: PropTypes.number,
  defaultColumnDefs: PropTypes.array.isRequired,
  gqlQuery: PropTypes.func.isRequired,
  responseDataKey: PropTypes.string.isRequired,
  localStorageKey: PropTypes.string,
  preserveSelection: PropTypes.bool,
  defaultSortModel: PropTypes.arrayOf(
    PropTypes.shape({
      colId: PropTypes.string.isRequired,
      sort: PropTypes.string.isRequired
    })
  ),
  frameworkComponents: PropTypes.object,
  filterButtons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      filterModel: PropTypes.object.isRequired,
      countQueryField: PropTypes.string
    })
  ),
  secondaryFilterButtons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      filterModel: PropTypes.object.isRequired,
      countQueryField: PropTypes.string
    })
  ),
  userFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      filterModel: PropTypes.string.isRequired,
    })
  ),
  pollingPathNameMatch: PropTypes.object,
  wrapperClass: PropTypes.string,
  rowHeight: PropTypes.number
};

export default CmpAgGrid;
